/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/react@16.14.0/umd/react.production.min.js
 * - /npm/react-dom@16.14.0/umd/react-dom.production.min.js
 * - /npm/react-is@16.13.1/umd/react-is.production.min.js
 * - /npm/styled-components@5.3.11/dist/styled-components.min.js
 * - /npm/@microlink/mql@0.10.39/dist/mql.min.js
 * - /npm/@microlink/hover-vanilla@5.5.23/dist/microlink.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
